/* eslint-disable santa/no-module-state */
const fastdom = require('fastdom')
const ResizeObserver = require('wix-resize-observer-polyfill').default
let _
let warmupUtilsLib
let wixCustomElementsRegistry

function init(dependencies) {
    _ = dependencies.lodash
    warmupUtilsLib = dependencies.warmupUtilsLib

    const resizeService = {
        init: callback => new ResizeObserver(callback)
    }

    wixCustomElementsRegistry = warmupUtilsLib.wixCustomElementsRegistry.init({resizeService})

    return {
        defineWixImage
    }
}

function defineWixImage(environmentConsts) {
    const biService = {
        reportBI: _.noop
    }

    wixCustomElementsRegistry.defineWixImage({mutationService: fastdom, biService}, environmentConsts)
}

module.exports = {
    init
}
